import React from "react"

import { Box, Grid, GridProps } from "@mui/material"

import { SolutionDetails, SolutionItem } from "../types"

import { SolutionCategoryItem } from "./SolutionCategoryItem"

interface SolutionDetailsPaneProps extends GridProps {
  solution: SolutionDetails | SolutionItem
}

export const SolutionDetailsPane: React.FC<SolutionDetailsPaneProps> = ({ solution, ...props }) => {
  return (
    <Grid
      data-control-id="solution-details"
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      alignContent="center"
      wrap="wrap"
      marginTop={2}
      paddingLeft={1}
      paddingRight={1}
      {...props}
    >
      {solution.author && (
        <Box>
          Author: <em>{solution.author.name || solution.author.email}</em>
        </Box>
      )}

      {solution.category && <SolutionCategoryItem category={solution.category} />}
    </Grid>
  )
}
