import { Routes } from "@blitzjs/next"
import { isNil, omitBy } from "lodash"
import { useRouter } from "next/router"
import React, { MouseEventHandler } from "react"

import { ButtonBase, ButtonBaseProps, Chip } from "@mui/material"

const ACTIVE = true

type TagColor = "primary" | "default"

interface SolutionTagButtonProps extends Pick<ButtonBaseProps, "sx"> {
  label: string
  size?: "small" | "medium"
  active?: boolean
}

export const SolutionTagButton: React.FC<SolutionTagButtonProps> = ({
  size = "medium",
  active = !ACTIVE,
  label,
  sx,
}) => {
  const router = useRouter()
  const { query } = router
  const color: TagColor = active ? "primary" : "default"
  const defaultActiveQuery = { tags: undefined }

  const handleTagClick: MouseEventHandler = async (event) => {
    event.stopPropagation()

    const activeQuery = active ? defaultActiveQuery : { tags: label }

    router.push(Routes.SolutionsPage(omitBy({ ...query, ...activeQuery }, isNil)))
  }

  return (
    <ButtonBase data-control-id="solution-tag-button" onClick={handleTagClick} sx={sx}>
      <Chip color={color} size={size} label={label} />
    </ButtonBase>
  )
}
