import { Routes } from "@blitzjs/next"
import { invalidateQuery } from "@blitzjs/rpc"
import { useRouter } from "next/router"
import { useState } from "react"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"

import { SolutionTag } from "@prisma/client"

import { Box, Grid, Paper, Typography } from "@mui/material"

import { SolutionItem } from "../types"

import getFilteredSolutions from "../queries/getFilteredSolutions"
import getSolutions from "../queries/getSolutions"

import { getValuesFromQuery } from "src/core/helpers/queryHelpers"

import Link from "src/core/components/Link"
import { SolutionTagButton } from "src/solution-tags/components/SolutionTagButton"

import { SolutionDetailsPane } from "./SolutionDetailsPane"
import { SolutionFavoriteStar } from "./SolutionFavoriteStar"

type SolutionsListPaneProps = {
  solution: SolutionItem
  fullWidth?: boolean
}

export const SolutionsListPane: React.FC<SolutionsListPaneProps> = ({ solution }) => {
  const router = useRouter()
  const currentUser = useCurrentUser()
  const [favoriteTag, setFavoriteTag] = useState<SolutionTag | null | undefined>(
    solution.favoriteTag
  )

  const { tags: tagsQuery } = router.query
  const selectedTags = getValuesFromQuery(tagsQuery)

  const getIsTagSelected = (tag: SolutionTag) => selectedTags.includes(tag.name)

  const handleToggleFavorite = (solutionTag: SolutionTag) => {
    setFavoriteTag(solutionTag)
    invalidateQuery(getSolutions)
    invalidateQuery(getFilteredSolutions)
  }

  return (
    <Link
      data-control-id="solutions-list-pane"
      href={Routes.ShowSolutionPage({ solutionId: solution.id })}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          m: 2,
          cursor: "pointer",
          display: "flex",
          minHeight: "8em",
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexGrow: 1,
              display: "flex",
              alignItems: "start",
            }}
          >
            <Typography component="div">{solution.name}</Typography>
            {!!currentUser && (
              <Box
                sx={{
                  mt: -1,
                  mr: -1,
                }}
              >
                <SolutionFavoriteStar
                  solutionId={solution.id}
                  favoriteTag={favoriteTag}
                  onToggleFavorite={handleToggleFavorite}
                  size="small"
                />
              </Box>
            )}
          </Box>
          {!!solution.tags?.length && (
            <Grid container gap={1} sx={{ mt: 1 }}>
              {solution.tags?.map((tag) => (
                <SolutionTagButton
                  key={tag.id}
                  label={tag.name}
                  size="small"
                  active={getIsTagSelected(tag)}
                  sx={{ justifyContent: "start" }}
                />
              ))}
            </Grid>
          )}
          <SolutionDetailsPane solution={solution} />
        </Box>
      </Paper>
    </Link>
  )
}
