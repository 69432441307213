import { useRouter } from "next/router"
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react"

import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { Button, IconButton, InputAdornment, TextField, Typography, styled } from "@mui/material"

const StyledTextField = styled(TextField)`
  fieldset {
    border-radius: 30px;
  }
`

type SearchBarProps = {
  onSearch: (searchQuery?: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const router = useRouter()
  const [searchInputRef] = useState(React.useRef<HTMLInputElement>())

  const routerSearchQuery = String(router.query.searchQuery || "")
  const [query, setQuery] = useState(routerSearchQuery)

  useEffect(() => {
    setQuery(routerSearchQuery)
  }, [routerSearchQuery])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value)
  }

  const focusSearchBox = () => {
    searchInputRef.current?.focus()
  }

  const handleSearch: MouseEventHandler = () => {
    onSearch(query)
  }

  const handlePressEnter: KeyboardEventHandler = (event) => {
    if (event.key !== "Enter") return

    onSearch(query)
    focusSearchBox()
  }

  const handleClearQuery: MouseEventHandler = () => {
    setQuery("")
    focusSearchBox()
  }

  return (
    <StyledTextField
      data-control-id="search-input"
      inputRef={searchInputRef}
      autoFocus
      fullWidth
      onChange={handleChange}
      onKeyPress={handlePressEnter}
      InputProps={{
        value: query,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {query && (
              <IconButton
                data-control-id="clear-search-input"
                color="secondary"
                aria-label="Clear search field"
                onClick={handleClearQuery}
              >
                <CloseIcon />
              </IconButton>
            )}
            <Button
              data-control-id="start-search"
              size="large"
              variant="contained"
              onClick={handleSearch}
              sx={{ borderRadius: "30px", padding: "12px 36px", zIndex: 1 }}
            >
              <Typography component="span" variant="h6">
                Search
              </Typography>
            </Button>
          </>
        ),
        sx: { pt: 0, pb: 0, pr: 0 },
      }}
      variant="outlined"
    />
  )
}

export default SearchBar
