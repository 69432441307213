import { Routes } from "@blitzjs/next"

import { Box, Paper, Typography } from "@mui/material"

import { QuestionItem } from "../types"

import Link from "src/core/components/Link"

import { QuestionDetailsPane } from "./QuestionDetailsPane"

type QuestionsListPaneProps = {
  question: QuestionItem
  fullWidth?: boolean
}

export const QuestionsListPane: React.FC<QuestionsListPaneProps> = ({
  question,
  fullWidth = false,
}) => {
  return (
    <Link
      data-control-id="questions-list-pane"
      href={Routes.ShowQuestionPage({ questionId: question.id })}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          m: 2,
          cursor: "pointer",
          display: "flex",
          minHeight: "8em",
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Typography component="div">{question.name}</Typography>
          <QuestionDetailsPane question={question} />
        </Box>
      </Paper>
    </Link>
  )
}
