import { useQuery } from "@blitzjs/rpc"
import { Suspense } from "react"

import { Prisma } from "@prisma/client"

import { Box, CircularProgress } from "@mui/material"

import getQuestions from "../queries/getQuestions"

import { QuestionsListPane } from "./QuestionsListPane"

type QuestionsListProps = {
  orderBy?: Prisma.QuestionOrderByWithAggregationInput
  take?: number
}

const ITEMS_TAKE = 3

const QuestionsListContent: React.FC<QuestionsListProps> = ({
  orderBy = { createdAt: "asc" },
  take = ITEMS_TAKE,
}) => {
  const [{ questions }] = useQuery(getQuestions, { orderBy, take })

  return (
    <Box data-control-id="question-list" sx={{ mt: 4 }}>
      {questions.map((question) => (
        <QuestionsListPane key={question.id} question={question} />
      ))}
    </Box>
  )
}

export const QuestionsList: React.FC<QuestionsListProps> = (props) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <QuestionsListContent {...props} />
    </Suspense>
  )
}
