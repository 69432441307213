import { useQuery } from "@blitzjs/rpc"
import { Suspense } from "react"

import { Prisma } from "@prisma/client"

import { Box, CircularProgress } from "@mui/material"

import getSolutions from "../queries/getSolutions"

import { SolutionsListPane } from "./SolutionsListPane"

type SolutionsListProps = {
  orderBy?: Prisma.SolutionOrderByWithAggregationInput
  take?: number
}

const ITEMS_TAKE = 3

const SolutionsListContent: React.FC<SolutionsListProps> = ({
  orderBy = { createdAt: "asc" },
  take = ITEMS_TAKE,
}) => {
  const [{ solutions }] = useQuery(getSolutions, { orderBy, take })

  return (
    <Box data-control-id="solution-list" sx={{ mt: 4 }}>
      {solutions.map((solution) => (
        <SolutionsListPane key={solution.id} solution={solution} />
      ))}
    </Box>
  )
}

export const SolutionsList: React.FC<SolutionsListProps> = (props) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <SolutionsListContent {...props} />
    </Suspense>
  )
}
