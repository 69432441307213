import Image, { ImageProps } from "next/image"
import React from "react"

import { Grid, GridProps, Typography } from "@mui/material"

interface FeatureItemProps extends GridProps {
  title: string
  image?: ImageProps
}

const FeatureItem: React.FC<FeatureItemProps> = ({
  title,
  children,
  image,
  xs = 8,
  sm = 8,
  md = 4,
  lg = 2,
  xl = 2,
}) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  const featureImage = image && <Image {...image} />

  return (
    <Grid
      data-control-id="feature-item"
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      margin={3}
      textAlign="center"
    >
      {featureImage}
      <Typography variant="h6" color="initial" marginTop={3} marginBottom={3} align="center">
        {title}
      </Typography>
      {children && (
        <Typography variant="body1" color="initial" align="center">
          {children}
        </Typography>
      )}
    </Grid>
  )
}

export default FeatureItem
