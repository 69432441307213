import Image from "next/image"
import Link, { LinkProps } from "next/link"
import React from "react"

import { Grid, GridProps, Paper, Typography, styled } from "@mui/material"

const StyledPaper = styled(Paper)`
  padding: 30px 40px;
  margin: 20px;
  cursor: pointer;
`

interface SolutionCategoryCardProps extends GridProps {
  name: string
  src: string
  nextLinkProps: LinkProps
}

export const SolutionCategoryCard: React.FC<SolutionCategoryCardProps> = ({
  src,
  name,
  nextLinkProps: { href },
  xs = 12,
  sm = 6,
  md = 4,
  lg = 4,
  xl = 4,
}) => {
  return (
    <Grid
      data-control-id="solution-category-card"
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      margin={0}
    >
      <Link href={href}>
        <StyledPaper
          elevation={5}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          {src && <Image src={src} alt={`${name} Icon`} width={30} height={30} />}
          <Typography variant="h6" component="p" marginTop={1}>
            {name}
          </Typography>
        </StyledPaper>
      </Link>
    </Grid>
  )
}
