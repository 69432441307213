import Image from "next/image"

import { SolutionCategory } from "@prisma/client"

import { Box } from "@mui/material"

type SolutionCategoryItemProps = {
  category?: SolutionCategory
  iconSize?: number
}

export const SolutionCategoryItem: React.FC<SolutionCategoryItemProps> = ({
  category,
  iconSize = 20,
}) => {
  return (
    <Box
      data-control-id="solution-category-item"
      sx={{ display: "flex", alignContent: "center", gap: 1, alignItems: "center" }}
    >
      {category && (
        <>
          {category.iconSrc && (
            <Image src={category.iconSrc} width={iconSize} height={iconSize} alt="" />
          )}
          <strong>{category.fullName}</strong>
        </>
      )}
    </Box>
  )
}
