import { Routes } from "@blitzjs/next"
import { usePaginatedQuery } from "@blitzjs/rpc"
import React, { Suspense, useState } from "react"

import { Box, Button, CircularProgress, Grid } from "@mui/material"

import getSolutionCategories from "src/solution-categories/queries/getSolutionCategories"

import { SolutionCategoryCard } from "./SolutionCategoryCard"

const ITEMS_PER_PAGE = 6

const SolutionCategoriesGridContent: React.FunctionComponent = () => {
  const [page, setPage] = useState(1)
  const [{ solutionCategories, hasMore }] = usePaginatedQuery(getSolutionCategories, {
    orderBy: { id: "asc" },
    take: ITEMS_PER_PAGE * page,
  })
  const handleLoadMore = () => setPage(page + 1)

  return (
    <>
      <Box data-control-id="solution-categories-grid" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {solutionCategories.map((category) => {
            return (
              <SolutionCategoryCard
                key={category.id}
                src={category.iconSrc}
                name={category.fullName}
                nextLinkProps={{ href: Routes.SolutionsPage({ category: category.shortName }) }}
              />
            )
          })}
        </Grid>
      </Box>
      {hasMore && (
        <Box sx={{ m: 3, display: "flex", alignSelf: "stretch" }}>
          <Button
            data-control-id="categories-load-more"
            color="secondary"
            variant="outlined"
            size="large"
            onClick={handleLoadMore}
            sx={{ flexGrow: 1 }}
          >
            More Categories
          </Button>
        </Box>
      )}
    </>
  )
}

export const SolutionCategoriesGrid = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <SolutionCategoriesGridContent />
    </Suspense>
  )
}
