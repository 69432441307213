import React from "react"

import { Box, Grid, GridProps } from "@mui/material"

import { QuestionDetails, QuestionItem } from "../types"

interface QuestionDetailsPaneProps extends GridProps {
  question: QuestionDetails | QuestionItem
}

export const QuestionDetailsPane: React.FC<QuestionDetailsPaneProps> = ({ question, ...props }) => {
  return (
    <Grid
      data-control-id="question-details"
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      alignContent="center"
      wrap="wrap"
      marginTop={2}
      paddingLeft={1}
      paddingRight={1}
      {...props}
    >
      {question.author && (
        <Box>
          Author: <em>{question.author.name || question.author.email}</em>
        </Box>
      )}
    </Grid>
  )
}
