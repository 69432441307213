import { BlitzPage, Routes } from "@blitzjs/next"
import { useFlags } from "flagsmith/react"
import Image from "next/image"
import { useRouter } from "next/router"
import Layout from "src/core/layouts/Layout"

import { Box, Container, Grid, Typography } from "@mui/material"

import banknotesIcon from "public/icons/banknotes.svg"
import chatDialogIcon from "public/icons/chat_dialog.svg"
import writerIcon from "public/icons/writer.svg"
import peopleWithQuestionsImage from "public/images/people_with_questions.svg"

import FeatureItem from "src/core/components/FeatureItem"
import SearchBar from "src/core/components/SearchBar"
import { QuestionsList } from "src/questions/components/QuestionsList"
import { SolutionCategoriesGrid } from "src/solution-categories/components/SolutionCategoriesGrid"
import { SolutionsList } from "src/solutions/components/SolutionsList"

const Home: BlitzPage = () => {
  const router = useRouter()

  const handleSearch = (searchQuery?: string) => {
    router.push(Routes.SolutionsPage({ searchQuery }))
  }

  const flags = useFlags(["home_page_people_image"])
  const hasPeopleImage = flags.home_page_people_image.enabled

  return (
    <Container maxWidth="lg">
      <Grid
        container
        component="main"
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <Box marginTop={5} alignContent="center">
          <Typography variant="h3" component="h1" align="center">
            INSTRUCT
          </Typography>
          <Typography variant="subtitle1" component="p" align="center">
            knows the answers to yours questions
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 5,
            ml: { xs: 0, md: 20 },
            mr: { xs: 0, md: 20 },
            flexGrow: 1,
            display: "flex",
            alignContent: "center",
            alignSelf: "stretch",
          }}
        >
          <SearchBar onSearch={handleSearch} />
        </Box>

        {hasPeopleImage && (
          <Box sx={{ mt: 10 }}>
            <Image src={peopleWithQuestionsImage} alt="People with questions" />
          </Box>
        )}

        <Grid container justifyContent="center" spacing={1} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={10} md={6} sx={{ marginTop: 12 }}>
            <Typography component="h3" variant="h4" textAlign="center">
              Last solutions
            </Typography>
            <SolutionsList orderBy={{ updatedAt: "desc" }} />
          </Grid>
          <Grid item xs={12} sm={10} md={6} sx={{ marginTop: 12 }}>
            <Typography component="h3" variant="h4" textAlign="center">
              Last questions
            </Typography>
            <QuestionsList orderBy={{ updatedAt: "desc" }} />
          </Grid>
        </Grid>

        <Box marginTop={12} marginBottom={2}>
          <Typography variant="h4" component="h2" align="center">
            About the project
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} justifyContent="center">
            <FeatureItem
              title="Quality of answers"
              image={{ src: chatDialogIcon, alt: "Chat Icon" }}
            >
              All answers are structured in steps, illustrated with pictures and a video manual is
              made for most of the questions.
            </FeatureItem>
            <FeatureItem
              title="Earn money with INSTRUCT DAO"
              image={{ src: banknotesIcon, alt: "Banknotes Icon" }}
            >
              If you understand any issues, then you can earn on our projects as an expert. To do
              this, select a question from the list of the most asked ones and answer step by step
              how to do something.
            </FeatureItem>
            <FeatureItem
              title="A place for creativity"
              image={{ src: writerIcon, alt: "Write Icon" }}
            >
              Creative people will also be able to earn by supplementing the answers with pictures
              or recording a video instruction.
            </FeatureItem>
          </Grid>
        </Box>

        <Box marginTop={12} marginBottom={4}>
          <Typography variant="h4" component="h2" align="center">
            Popular Categories
          </Typography>
        </Box>

        <SolutionCategoriesGrid />
      </Grid>
    </Container>
  )
}

Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => (
  <Layout title="INSTRUCT - HowTos and step-by-step guides" hasFloatingMenuButton>
    {page}
  </Layout>
)

export default Home
