import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import { FC, MouseEventHandler } from "react"

import { SolutionTag } from "@prisma/client"
import { TagType } from "db"

import StarIcon from "@mui/icons-material/Star"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import { IconButton } from "@mui/material"

import { trackError } from "integrations/errors"

import getSolutionFavoriteTag from "src/solution-tags/queries/getSolutionFavoriteTag"

import createSolutionTag from "src/solution-tags/mutations/createSolutionTag"
import deleteSolutionTag from "src/solution-tags/mutations/deleteSolutionTag"

type SolutionFavoriteStarProps = {
  solutionId: number
  favoriteTag?: SolutionTag | null | undefined
  size?: "small" | "large" | "medium" | undefined
  onToggleFavorite?: (solutionTag: SolutionTag | null) => void
}

/**
 * @requires User should be logged in
 */
export const SolutionFavoriteStar: FC<SolutionFavoriteStarProps> = ({
  solutionId,
  favoriteTag,
  onToggleFavorite,
  size = "medium",
}) => {
  const [createSolutionTagMutation] = useMutation(createSolutionTag)
  const [deleteSolutionTagMutation] = useMutation(deleteSolutionTag)
  const StatusStarIcon = !!favoriteTag ? StarIcon : StarBorderIcon

  const defaultNewTag = {
    name: "Favorite",
    type: TagType.FAVORITE,
  }

  const handleToogleFavorite: MouseEventHandler = async (event) => {
    event.stopPropagation()

    try {
      if (!!favoriteTag) {
        await deleteSolutionTagMutation({ id: favoriteTag.id })
        onToggleFavorite?.(null)
      } else {
        const tag = await createSolutionTagMutation({ ...defaultNewTag, solutionId })
        onToggleFavorite?.(tag)
      }
    } catch (error) {
      trackError(error, { solutionId, favoriteTag, onToggleFavorite })
    }
  }

  return (
    <IconButton data-control-id="toggle-favorite" onClick={handleToogleFavorite} size={size}>
      <StatusStarIcon color="primary" fontSize={size} />
    </IconButton>
  )
}

type SolutionFavoriteStarFetcherProps = {
  solutionId: number
  size?: "small" | "large" | "medium" | undefined
}

export const SolutionFavoriteStarFetcher: FC<SolutionFavoriteStarFetcherProps> = ({
  solutionId,
  size,
}) => {
  const [favoriteTag] = useQuery(getSolutionFavoriteTag, { solutionId })

  const handleToogleFavorite = () => {
    invalidateQuery(getSolutionFavoriteTag)
  }

  return (
    <SolutionFavoriteStar
      data-control-id="solution-favorite-star"
      solutionId={solutionId}
      size={size}
      favoriteTag={favoriteTag}
      onToggleFavorite={handleToogleFavorite}
    />
  )
}
